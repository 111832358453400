import React, { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import data from "layouts/dashboard/components/Transactions/data";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useConfig } from "../../../../config"
// import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useSandbox } from "ZustandState/useSandbox";
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { Tooltip } from "@mui/material";
import { useSnackBar } from "ZustandState/useSnackBar";
import { useCsvReport } from "ZustandState/useCsvReport";
import { ScaleLoader } from "react-spinners";
function Transactions() {
    const setCsvReport = useCsvReport((state) => state.setCsvReport)
    const openWarningSB = useSnackBar((state) => state.openWarningSB);
    const openSuccessSB = useSnackBar((state) => state.openSuccessSB);
    const [controller] = useMaterialUIController()
    const sandbox = useSandbox((state) => state.sandbox)
    const { darkMode } = controller
    const { apiUrl } = useConfig()
    const token = localStorage.getItem('user_token');
    const userEmail = localStorage.getItem('user_email');
    const userApiKey = localStorage.getItem('user_apiKey');
    const [transactions, setTransactions] = useState([])
    const [totalTransaction, setTotaltransaction] = useState([])
    const [menu, setMenu] = useState(null);
    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);
    const [page, setPage] = useState(0)
    const [check, setCheck] = useState("all")
    const [currentStatus, setCurrentStatus] = useState("")
    const [reload, setReload] = useState(true)
    const [isGeneratebtn, setIsGeneratebtn] = useState(true)
    const [isSearch, setIsSearch] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [callbackValues, setCallbackValues] = useState()
    const [searchLoading, setSearchLoading] = useState(false)
    let limit = 10;
    const formikRef = React.createRef();
    const handleResetForm = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };
    const validationSchemaCallback = Yup.object({
        status: Yup.string().required('Status is required').oneOf(['success', 'failed'], 'Status must be either success or failed'),
    });
    const initialSearchValues = {
        emailId: userEmail || '',
        utr: '',
    };
    const initialCallbackValues = {
        emailId: userEmail,
        status: ''
    }

    const initialDateValues = {
        emailId: userEmail || '',
        start_date: '',
        end_date: ''
    };
    const [formikValues, setFormikValues] = useState(initialDateValues);
    const renderMenu = (
        <Menu
            id="simple-menu"
            anchorEl={menu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={() => {
                setMenu(null);
                setCheck("all")
                setPage(0)
                setIsGeneratebtn(true)
                setReload(!reload)
                handleResetForm()
            }}>
                All
            </MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("success")
                setPage(0)
                setMenu(null);
                setCheck("status")
                setIsGeneratebtn(true)
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearchWithStatus(formikValues, "success") : filterSearch("success")
            }}>Success</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("IN-PROCESS")
                setPage(0)
                setMenu(null);
                setCheck("status")
                setIsGeneratebtn(true)
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearchWithStatus(formikValues, "IN-PROCESS") : filterSearch("IN-PROCESS")
            }}>Pending</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("failed")
                setPage(0)
                setMenu(null);
                setCheck("status")
                setIsGeneratebtn(true)
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearchWithStatus(formikValues, "failed") : filterSearch("failed")
            }}>Failed</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("expired")
                setPage(0)
                setMenu(null);
                setCheck("status")
                setIsGeneratebtn(true)
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearchWithStatus(formikValues, "expired") : filterSearch("expired")
            }}>Expired</MenuItem>
        </Menu>
    );
    const handleSearch = async (values) => {
        try {
            if (values.utr === "") {
                toast.error("Search field cannot be empty!", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 2 seconds
                    hideProgressBar: false,
                });

                return;
            }

            setSearchLoading(true)
            const response = await fetch(`${apiUrl}/user/getdatabyutr`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey || '',
                    "token": token
                },
                body: JSON.stringify(values)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTransactions(res.responseData)
            setIsSearch(true)
            setSearchLoading(false)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getAllTransactions = async () => {
        let body = {
            emailId: userEmail,
            limit,
            skip: limit * page
        }
        try {
            const response = await fetch(`${apiUrl}/user/getTransactionsUser`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey,
                    "token": token
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTotaltransaction(res?.responseData)
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getAllTransactionsForCsv = async (body) => {
        try {
            const response = await fetch(`${apiUrl}/user/downloadUserTransactionCsv`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey,
                    "token": token
                },
                body: JSON.stringify(body)
            })
            const res = await response.json()
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const filterSearch = async (status) => {
        let body = {
            emailId: userEmail,
            status,
            limit,
            skip: limit * page
        }
        setCurrentStatus(status)
        setCheck("status")
        try {
            const response = await fetch(`${apiUrl}/user/getTransactionsByStatus`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey,
                    "token": token
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) {
                setTransactions([])
                return;
            }
            setTotaltransaction(res?.responseData)
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const filterSearchForCsv = async (body) => {
        try {
            const response = await fetch(`${apiUrl}/user/downloadUserTransactionCsvWithStatus`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey,
                    "token": token
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            return res.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }

    const handleDateSearch = async (values) => {
        try {
            if (values.start_date === "" || values.end_date === "") {
                return;
            }
            setCheck("date")
            // setIsGeneratebtn(true)
            values.limit = limit
            values.skip = limit * page
            const response = await fetch(`${apiUrl}/user/getTransactionsByDate`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey || '',
                    "token": token
                },
                body: JSON.stringify(values)
            })
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTransactions(res.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const handleDateSearchForCsv = async (values) => {
        try {
            if (values.start_date === "" || values.end_date === "") {
                return;
            }
            const response = await fetch(`${apiUrl}/user/downloadUserTransactionCsvByDate`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey || '',
                    "token": token
                },
                body: JSON.stringify(values)
            })
            const res = await response.json()
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            return res.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const handleDateSearchWithStatus = async (values, status) => {
        try {
            if (values.start_date === "" || values.end_date === "" || status === "") {
                return;
            }
            setCheck('dateStatus')
            values.status = status
            values.limit = limit,
                values.skip = limit * page
            const response = await fetch(`${apiUrl}/user/getTransactionsByStatusAndDate`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey || '',
                    "token": token
                },
                body: JSON.stringify(values)
            })
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }

    const handleDateSearchWithStatusForCsv = async (values) => {
        try {
            if (values.start_date === "" || values.end_date === "") {
                return;
            }
            // setCheck('dateStatus')
            const response = await fetch(`${apiUrl}/user/downloadUserTransactionCsvByDateWithStatus`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey || '',
                    "token": token
                },
                body: JSON.stringify(values)
            })
            const res = await response.json()
            if (res?.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }


    const generateCsv = async (type) => {
        try {
            openWarningSB()
            setIsGeneratebtn(false)
            let csvLink;
            if (type === "all") {
                let body = {
                    emailId: userEmail || '',
                }
                csvLink = await getAllTransactionsForCsv(body)
            }
            else if (check === "status") {
                let body = {
                    emailId: userEmail || '',
                    status: type,
                }
                csvLink = await filterSearchForCsv(body)
            }
            else if (check === "date") {
                let body = {
                    emailId: userEmail || '',
                    start_date: formikValues.start_date,
                    end_date: formikValues.end_date
                }
                csvLink = await handleDateSearchForCsv(body)
            }
            else if (check === "dateStatus") {
                let body = {
                    emailId: userEmail || '',
                    status: type,
                    start_date: formikValues.start_date,
                    end_date: formikValues.end_date
                }
                csvLink = await handleDateSearchWithStatusForCsv(body)
            } else if (check === "date") {
                let body = {
                    emailId: userEmail || '',
                    start_date: formikValues.start_date,
                    end_date: formikValues.end_date
                }
                csvLink = await handleDateSearchForCsv(body)
            }
            else {
                toast.error("Something is wrong ! Please try again")
                setIsGeneratebtn(true)
                return
            }
            setCsvReport(csvLink)
            openSuccessSB()
            // const a = document.createElement('a');
            // a.href = csvLink;
            // a.download = 'transaction.csv';
            // a.click(); // Programmatically click the link to trigger the download
            // URL.revokeObjectURL(csvLink);  // Clean up
            setIsGeneratebtn(true)

        } catch (err) {
            toast.error("Error downloading! Try again", {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
        }

    }
    const sendAutoCallback = async (values, { setSubmitting }) => {
        try {
            if (!sandbox) return
            if (values.status === '') return
            values.transaction_id = callbackValues.transaction_id
            values.amount = callbackValues.amount
            const response = await fetch(`${apiUrl}/callback/autoCallback`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'apiKey': userApiKey || '',
                    "token": token
                },
                body: JSON.stringify(values)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) {
                toast.error('Something went wrong.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                })
            }
            if (res?.responseCode === 200) {

                toast.success(res?.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                })
            }
            getAllTransactions()
            handleCloseDialog()
        } catch (err) {
            console.log("Error Fetching sendAutoCallback: ", err)
        }
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const callbackValueSetter = (value) => {
        setCallbackValues({
            transaction_id: value.transaction_id,
            amount: value.amount
        })
    }

    useEffect(() => {
        if (check === "all") {
            getAllTransactions()
        }
        else if (check === "status") {
            filterSearch(currentStatus)
        } else if (check === "dateStatus") {
            handleDateSearchWithStatus(formikValues, currentStatus)
        }
        else if (check === "date") {
            handleDateSearch(formikValues)
        }
    }, [page, reload, sandbox])
    const { columns, rows } = data(transactions, isSearch, setOpenDialog, callbackValueSetter);
    return (
        <>
            <Card>
                <MDBox display={'flex'} flexDirection={'column'}>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
                        <Grid container alignItems="center" p={1}>
                            <Grid item xl={3}>
                                <MDBox ml={1} alignItems="center">
                                    <MDTypography variant="h6" gutterBottom >
                                        ALL TRANSACTION
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xl={9}>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item xl={4} sm={12} xs={12}>
                                        <Grid item xl={12} xs={12} sm={12}>
                                            {/* search input */}
                                            <Formik
                                                initialValues={initialSearchValues}
                                                onSubmit={handleSearch}
                                            >
                                                {({ setFieldValue }) => (
                                                    <Form>
                                                        <MDBox mb={0.5}>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Search by UTR/TXID/Username
                                                            </MDTypography>
                                                            <Field
                                                                onChange={(e) => {
                                                                    setFieldValue('utr', e.target.value)
                                                                    if (initialSearchValues.utr === '') {
                                                                        setTransactions(totalTransaction)
                                                                        setIsSearch(false)
                                                                    }
                                                                }}
                                                                type="text" as={MDInput} name="utr" fullWidth
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {
                                                                                searchLoading ? <ScaleLoader height={15} width={2} color={darkMode ? "white" : "black"} /> : (
                                                                                    <IconButton type="submit" edge="end">
                                                                                        {
                                                                                            <SearchIcon color={darkMode ? "white" : "black"} />
                                                                                        }
                                                                                    </IconButton>
                                                                                )
                                                                            }

                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="utr" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={4} sm={12} xs={12}>
                                        {/* date input */}
                                        <Formik
                                            initialValues={initialDateValues}
                                            onSubmit={handleDateSearch}
                                            innerRef={formikRef}
                                        >
                                            {({ setFieldValue, values }) => (
                                                <Form>
                                                    <Grid container spacing={1}>
                                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                            <MDBox>
                                                                <MDTypography variant="caption" color="text" ml={1}>
                                                                    Start date
                                                                </MDTypography>
                                                                <Field
                                                                    size="large"
                                                                    onChange={async (e) => {
                                                                        await setFieldValue('start_date', e.target.value)
                                                                        const newFormikValues = { ...formikValues, start_date: e.target.value };
                                                                        setFormikValues(newFormikValues);
                                                                        setPage(0)
                                                                        handleDateSearch({ ...values, start_date: e.target.value });
                                                                    }}
                                                                    type="date" as={MDInput} fullWidth name="start_date" />
                                                                <MDTypography color="error" variant="caption" >
                                                                    <ErrorMessage name="start_date" component="div" />
                                                                </MDTypography>
                                                            </MDBox>
                                                        </Grid>
                                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                            <MDBox>
                                                                <MDTypography variant="caption" color="text" ml={1}>
                                                                    End date
                                                                </MDTypography>
                                                                <Field
                                                                    size="large"
                                                                    onChange={async (e) => {
                                                                        await setFieldValue('end_date', e.target.value)
                                                                        const newFormikValues = { ...formikValues, end_date: e.target.value };
                                                                        setFormikValues(newFormikValues);
                                                                        setPage(0)
                                                                        handleDateSearch({ ...values, end_date: e.target.value });
                                                                    }}
                                                                    type="date" as={MDInput} fullWidth name="end_date" />
                                                                <MDTypography color="error" variant="caption" >
                                                                    <ErrorMessage name="end_date" component="div" />
                                                                </MDTypography>
                                                            </MDBox>
                                                        </Grid>
                                                    </Grid>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Grid>
                                    <Grid item xl={4} sm={12} xs={12} mt={4}>
                                        <Grid container spacing={1}>
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                <MDBox>
                                                    <MDButton
                                                        variant="contained"
                                                        startIcon={<TuneOutlinedIcon />}
                                                        onClick={openMenu}
                                                        color={"success"}
                                                        fullWidth
                                                    >
                                                        Filter
                                                    </MDButton>
                                                    {renderMenu}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                <MDBox>
                                                    <Tooltip
                                                        title={isGeneratebtn === false ? "Download report is in progress" : ""}
                                                        placement="top"
                                                    >
                                                        <span>
                                                            {/* Wrapping the button in a <span> ensures proper Tooltip behavior for disabled buttons */}
                                                            <MDButton
                                                                variant="contained"
                                                                color="info"
                                                                startIcon={<FileDownloadOutlinedIcon />}
                                                                onClick={() =>
                                                                    generateCsv(
                                                                        check === "all" ? check : check === "date" ? "date" : currentStatus
                                                                    )
                                                                }
                                                                fullWidth
                                                                disabled={!isGeneratebtn} // Disable the button if isGenerateButton is false
                                                            >
                                                                Download
                                                            </MDButton>
                                                        </span>
                                                    </Tooltip>
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox>
                        <DataTable
                            table={{ columns, rows }}
                            showTotalEntries={false}
                            isSorted={false}
                            noEndBorder
                            entriesPerPage={false}
                            pages={page}
                            pageSetter={setPage}

                        />
                    </MDBox>
                </MDBox>

            </Card >

            {/* dialog for callback */}

            < Dialog PaperProps={{
                style: {
                    minHeight: '25vh',
                    minWidth: '45vw',
                    background: darkMode ? "#344767" : "white",
                    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', // Shadow effect
                    borderRadius: '10px', // Rounded corners
                    position: 'absolute',
                    left: '50%', // Adjust the left value to move the dialog to the right
                },
            }
            } open={openDialog} onClose={handleCloseDialog} >
                <DialogTitle>Send Callback</DialogTitle>
                <DialogContent>
                    <MDBox px={1} py={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="large" variant="caption" >
                            Tx Id: {callbackValues?.transaction_id}
                        </MDTypography>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="large" variant="caption" >
                            Amount: {callbackValues?.amount}
                        </MDTypography>
                    </MDBox>
                    <Formik
                        initialValues={initialCallbackValues}
                        validationSchema={validationSchemaCallback}
                        onSubmit={sendAutoCallback}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <MDBox mb={2}>
                                    <Field
                                        type="text"
                                        select
                                        label="Select Status"
                                        size="large"
                                        as={MDInput}
                                        fullWidth
                                        name="status"
                                        InputProps={{
                                            classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                        }}
                                    >
                                        <MenuItem value={'success'}>SUCCESS</MenuItem>
                                        <MenuItem value={'failed'}>FAILED</MenuItem>
                                    </Field>
                                </MDBox>
                                <MDBox display={'flex'} flexDirection={'row'}>
                                    <MDBox>
                                        <MDButton
                                            type="submit" color="success" disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Sending Callback...' : 'Send Callback'}
                                        </MDButton>
                                    </MDBox>
                                    <MDBox ml={3}>
                                        <MDButton
                                            onClick={handleCloseDialog} color="error"
                                        >
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}

                    </Formik>
                </DialogContent>

            </Dialog >
        </>
    );
}

export default Transactions;

import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import MDInput from "components/MDInput"
import { Formik, ErrorMessage, Field, Form } from "formik"
import MDButton from "components/MDButton"
import { Card, Grid } from "@mui/material"
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard"
import { useState, useEffect } from "react"
import { useConfig } from "../../config"
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify"
import Paper from '@mui/material/Paper';
import { useMaterialUIController } from "context"
import * as Yup from 'yup';
function DevSettings() {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const navigate = useNavigate()
    const { apiUrl } = useConfig()
    const token = localStorage.getItem('user_token')
    const apiKey = localStorage.getItem('user_apiKey');
    const userEmail = localStorage.getItem('user_email')
    const [encryptionKey, setEncryptionKey] = useState('')
    const [urls, setUrls] = useState({
        callbackUrl: '',
        payoutCallbackUrl: '',
        redirectUrl: ''
    })
    const [user, setUser] = useState({
        emailId: '',
        apiKey: '',
        first_name: '',
        last_name: '',
        business_name: ''
    })
    const initialValues = {
        emailId: userEmail || '',
        redirectUrl: urls?.redirectUrl,
        payoutCallbackUrl: urls?.payoutCallbackUrl,
        callbackUrl: urls?.callbackUrl
    }
    const validationSchema = Yup.object({
        redirectUrl: Yup.string(),
        callbackUrl: Yup.string(),
        payoutCallbackUrl: Yup.string()
    });
    async function getProfile(body) {
        try {
            const response = await fetch(`${apiUrl}/user/getprofile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': apiKey || ''
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res?.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                localStorage.removeItem('user_token');
                navigate('/authentication/sign-in');
                return;
            }
            if (res.responseData) {
                setUser(res.responseData)
                setUrls({
                    callbackUrl: res.responseData?.callbackUrl || '',
                    payoutCallbackUrl: res.responseData?.payoutCallbackUrl || '',
                    redirectUrl: res.responseData?.redirectUrl || ''
                });
            }

        } catch (e) {
            console.log("Error Fetching profile: ", e)
        }
    }

    async function getEncryptionKey(body) {
        try {
            if (body.emailId === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/user/getencryptionkey`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': apiKey || ''
                },
                body: JSON.stringify(body)
            })
            const res = await response.json()
            console.log(res)
            if (res?.responseCode !== 200) return
            setEncryptionKey(res?.responseData?.encryptedKey)
        } catch (err) {
            console.log("Error Fetching Encryption key: ", err)
        }
    }
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/user/updateDeveloperUrls`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': apiKey || ''
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    }
    useEffect(() => {
        const userBody = {
            emailId: userEmail
        }
        getProfile(userBody)
        getEncryptionKey(userBody)
    }, [])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={1} mt={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} xl={6} sx={{ display: "flex" }}>
                        <ProfileInfoCard
                            title="Keys"
                            info={{
                                Apikey: apiKey ? apiKey: "",
                                encryptionKey: encryptionKey || '',

                            }}
                            showBtnTest={true}
                            action={{ route: "https://docs.payhub.link", tooltip: "Payhub Documentation" }}
                            shadow={false}
                        />
                    </Grid>   
                    <Grid item xs={12} md={12} xl={6} sx={{ display: "flex" }}>
                    <ProfileInfoCard
                            title="API URL"
                            info={{
                                Live: "https://server.payhub.link",
                                Sandbox: "https://sandbox.payhub.link",

                            }}
                            action={{ route: "", tooltip: "Edit Profile" }}
                            shadow={false}
                        />
                        {/* <Card  sx={{ 
                            p: 3, 
                            borderRadius: 2, 
                            display: 'flex', 
                            flexDirection: 'column', 
                            gap: 2, 
                            minWidth: {xs:'380px',sm:'400px'}, 
                            margin: 'auto',
                        }}>
                            <MDBox sx={{ borderBottom: '1px solid #e0e0e0', pb: 1, mb: 2 }}>
                                <MDTypography variant="h6">API URL</MDTypography>
                            </MDBox>
                            <MDBox display="flex" justifyContent="space-between" alignItems="center" sx={{ gap: 2 }}>
                                <MDTypography variant="caption" color="text">Live:</MDTypography>
                                <MDTypography variant="caption" fontWeight="medium" >
                                    {"https://server.payhub.link"}
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex" justifyContent="space-between" alignItems="center" sx={{ gap: 2 }}>
                            <MDTypography variant="caption" color="text">Sandbox:</MDTypography>
                            <MDTypography variant="caption" fontWeight="medium" >
                                {"https://sandbox.payhub.link"}
                            </MDTypography>
                            </MDBox>
                        </Card> */}
                    </Grid>
                </Grid>
            </MDBox>
            {/* Use MDBox to replace Container for full-width */}
            <MDBox component="main" sx={{ width: "100%", p: 0, mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, backgroundColor: darkMode ? '#344767' : 'white' }}>
                    <MDTypography variant="h6" gutterBottom mb={4}>
                        Update URLs
                    </MDTypography>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <MDBox mb={2}>
                                            <Field type="text" label="Payin Callback URL" as={MDInput} fullWidth name="callbackUrl" />
                                            <MDTypography color="error" variant="caption">
                                                <ErrorMessage name="callbackUrl" component="div" />
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MDBox mb={2}>
                                            <Field type="text" label="Payout Callback URL" as={MDInput} fullWidth name="payoutCallbackUrl" />
                                            <MDTypography color="error" variant="caption">
                                                <ErrorMessage name="payoutCallbackUrl" component="div" />
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} xl={12} sm={12} xs={12}>
                                    <MDBox mb={2}>
                                        <Field type="text" label="Redirect URL" as={MDInput} fullWidth name="redirectUrl" />
                                        <MDTypography color="error" variant="caption">
                                            <ErrorMessage name="redirectUrl" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                                <MDBox mt={4} mb={1} justifyContent="right">
                                    <Grid container justifyContent="right">
                                        <Grid item display={'flex'}>
                                            <MDBox>
                                                <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </MDBox>
        </DashboardLayout>
    )
}
export default DevSettings